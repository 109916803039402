import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";
// eslint-disable-next-line import/no-cycle
import { RootState } from "..";
import { ApQuestionData, Content, Subject, Topic, Unit } from "../api/graphql/generated/types";
import { BaseHydrateActionType } from "../shared";

export interface PageDataState {
  subject?: Pick<Subject, "id" | "name" | "generationMetadata">;
  unit?: Unit;
  topic?: Topic;
  content?: Content;
  apQuestionData?: ApQuestionData;
  subjectSelectList: Partial<Subject>[];
}

const initialState: PageDataState = {
  subject: null,
  unit: null,
  topic: null,
  content: null,
  apQuestionData: null,
  subjectSelectList: [],
};

export type SetPageDataActionPayload = PageDataState;

interface HydrateActionType extends BaseHydrateActionType {
  pageData: PageDataState;
}

const HYDRATE_ACTION = createAction<HydrateActionType>(HYDRATE);

export const slice = createSlice({
  name: "pageData",
  initialState,
  reducers: {
    setSubjectSelectList: (state, action: PayloadAction<Partial<Subject>[]>) => {
      const { payload } = action;
      state.subjectSelectList = payload?.length > 0 ? payload : [];
    },
    setInitialPageData: (state, action: PayloadAction<SetPageDataActionPayload>) => {
      const { payload } = action;
      state.subject = payload.subject
        ? {
            id: payload.subject.id,
            name: payload.subject.name,
            generationMetadata: payload?.subject?.generationMetadata || {},
          }
        : null;
      state.unit = payload.unit ?? null;
      state.topic = payload.topic ?? null;
      state.content = payload.content ?? null;
      state.apQuestionData = payload.apQuestionData ?? null;
    },
  },
  extraReducers(builder) {
    builder.addCase(HYDRATE_ACTION, (state, action) => {
      /* eslint-disable no-param-reassign */
      if (action.payload.app === "init") delete action.payload.app;
      if (action.payload.page === "init") delete action.payload.page;
      if (action.payload?.pageData) {
        const { pageData } = action.payload;
        state.subject = pageData.subject ?? null;
        state.unit = pageData.unit ?? null;
        state.topic = pageData.topic ?? null;
        state.content = pageData.content ?? null;
        state.apQuestionData = pageData.apQuestionData ?? null;
      }
    });
  },
});

export const { setInitialPageData, setSubjectSelectList } = slice.actions;

export const selectPageData = (state: RootState): PageDataState => state.pageData;
export const selectApQuestionData = (state: RootState): ApQuestionData[] =>
  state?.pageData.apQuestionData ?? [];

export default slice.reducer;
